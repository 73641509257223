import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { generateRoutes } from '../util/functions/routeGenerators'
import { homePath, navRoutes } from './routes'

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Navigate replace to={homePath} />} />
        {generateRoutes(navRoutes)}
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
