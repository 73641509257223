const LoadingPage = () => {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: '0',
        left: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f0f2f5',
        overflow: 'hidden',
      }}
    >
      <svg
        width='200'
        height='200'
        viewBox='0 0 432 133'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M160.46 61.71V12.09h19.15c10.55 0 16.63 4.33 16.63 12.37 0 6.08-3.14 9.23-6.5 10.34 5.45 1 9.3 5.87 9.3 12 0 8.53-6.22 14.89-18.17 14.89l-20.41.02Zm10.14-29.49h9c3.84 0 6.43-1.89 6.43-5.73 0-3.84-2.59-5.73-6.43-5.73h-9v11.46Zm0 20.82h10.27c4.89 0 8-2.51 8-6.43 0-4.12-3.08-6.71-7.83-6.71H170.6v13.14Zm32.77-9.64c0-11 8.25-19 19.71-19s19.78 8 19.78 19-8.25 19-19.78 19c-11.53 0-19.71-7.89-19.71-19Zm29.91 0a10.138 10.138 0 0 0-12.047-10.039 10.14 10.14 0 0 0 1.847 20.099 9.8 9.8 0 0 0 10.2-10.06Zm13.56 0c0-11 8.24-19 19.71-19s19.77 8 19.77 19-8.24 19-19.77 19-19.71-7.89-19.71-19Zm29.91 0a10.138 10.138 0 0 0-12.047-10.039 10.14 10.14 0 0 0 1.847 20.099 9.8 9.8 0 0 0 10.2-10.06Zm50.6 18.31H315.6l-14-17c.42 3.91.49 9 .49 17h-9.93V9.29h9.93c0 18 0 25.37-.56 30.61l14-14.74h11.68l-16.52 17.12 16.66 19.43Zm4.47-45.78a5.592 5.592 0 0 1 9.543-3.953 5.589 5.589 0 0 1-.847 8.6c-.92.615-2 .943-3.106.943a5.561 5.561 0 0 1-5.59-5.59Zm10.55 9.23v36.55h-9.92V25.16h9.92Zm43.61 15.23v21.32h-10.07V41.93c0-7.06-3.91-9-7.75-9-4.83 0-8.18 3-8.18 9.36v19.43h-10.06V25.16h8.38l1.54 5.24c2.31-3.77 6-6 11.39-6 7.69-.01 14.75 4.81 14.75 15.99Zm37.46-15.23h8.1v32.78c0 12.71-6.78 21-19.85 21-5.66 0-12.57-1.68-16.07-4.33v-8.57c3 1.68 7.76 4.27 14.05 4.27 7.76 0 11.95-3.78 11.95-12.37v-2.52c-1.75 4.33-6.57 7-12.65 7-11.11 0-17.68-8.67-17.68-19.08 0-10.83 6.92-18.94 18.17-18.94a14.61 14.61 0 0 1 12.16 6.15v.21l1.82-5.6Zm-1.82 18.24a9.703 9.703 0 0 0-2.86-7.183 9.706 9.706 0 0 0-7.2-2.817c-6 0-10.21 4.12-10.21 10s4.19 10.13 10.21 10.13a9.78 9.78 0 0 0 10.06-10.13Z'
          fill='#003049'
        />
        <path
          d='M180.52 81.27c10.55 0 18.38 5.94 18.38 16.7 0 10.55-7.83 17.26-18.94 17.26h-9.36v15.66h-10.14V81.27h20.06Zm0 24.67c4.75 0 8.25-2.73 8.25-7.69 0-4.96-3.5-7.76-8.25-7.76h-9.92v15.45h9.92Zm21.38 6.64c0-11 8.25-19 19.71-19s19.78 8 19.78 19-8.25 19-19.78 19c-11.53 0-19.71-7.89-19.71-19Zm29.91 0a10.137 10.137 0 0 0-1.668-5.64 10.138 10.138 0 0 0-10.369-4.379 10.133 10.133 0 0 0-7.478 13.761 10.134 10.134 0 0 0 9.315 6.318 9.798 9.798 0 0 0 7.282-2.818 9.811 9.811 0 0 0 2.918-7.242Zm25.09-13.54c1.89-3.36 5.17-5.45 11-4.75l-.07 8.52c-6.85-.84-10.69 1.47-10.69 7.2v20.9h-9.95V94.34h8.1l1.61 4.7Zm38.86-4.7v7.82h-8.46v28.73h-9.92v-28.73h-5.94v-7.82h5.94v-10.7h9.92v10.7h8.46Zm33.82 0h8.39v36.55H327.9v-6.29c-1.74 4.33-6.57 7-12.65 7-11.11 0-17.68-8.66-17.68-19.07 0-10.84 6.92-18.94 18.17-18.94a14.56 14.56 0 0 1 12.16 6.15v.21l1.68-5.61Zm-1.68 18.24a9.7 9.7 0 0 0-2.86-7.183 9.706 9.706 0 0 0-7.2-2.817c-6 0-10.2 4.13-10.2 10s4.19 10.13 10.2 10.13a9.785 9.785 0 0 0 9.394-6.213 9.78 9.78 0 0 0 .666-3.917Zm27.54-34.11v52.42h-9.93V78.47h9.93ZM131.27 51.51A66.5 66.5 0 0 0 69.69.04a65 65 0 0 0-14 .84v35.16h20.49c11 .21 20.44 3.62 27.15 9.61 7 6.22 11 15.17 11 26.13 0 22.32-16.23 34.64-38.11 34.64h-8.86a32.7 32.7 0 0 1-32.67-32.66V8.13A66.47 66.47 0 0 0 .2 61.9a62.17 62.17 0 0 0 1.49 19.27c5.12 20.88 17.33 36.11 36.66 45.52a59.522 59.522 0 0 0 25.63 6.16c10.91.12 21.83 0 32.75 0h34.68c1.59 0 1.59 0 1.59-1.56V66.5a66.295 66.295 0 0 0-1.73-14.99Z'
          fill='#F77F00'
        />
        <path
          d='M67.36 106.46h8.89c21.88 0 38.11-12.32 38.11-34.64 0-11-4.08-19.91-11-26.13-6.71-6-16.13-9.4-27.15-9.61H55.69V.92a66 66 0 0 0-21 7.21v65.63a32.7 32.7 0 0 0 32.67 32.7ZM55.69 55.35h20.57c9.85 0 17.1 5.65 17.1 15.94s-7.25 16.08-17.1 16.08a20.57 20.57 0 0 1-20.57-20.62v-11.4Z'
          fill='#003049'
        />
      </svg>
    </div>
  )
}

export default LoadingPage
