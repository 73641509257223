import { selectCurrentProperty, selectLocale } from '../redux/global/selectors'

import { ConfigProvider, theme } from 'antd'
import enLocale from 'antd/lib/locale/en_US'
import esLocale from 'antd/lib/locale/es_ES'
import { useIntl, IntlProvider } from 'react-intl'
import { useEffect } from 'react'
import { connect } from 'react-redux'

import enLanguage from './languages/en.json'
import esLanguage from './languages/es.json'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'
import 'dayjs/locale/es'

dayjs.extend(localizedFormat)
dayjs.extend(customParseFormat)
dayjs.extend(isBetween)

const supportedLocales = {
  en: enLocale,
  es: esLocale,
}

const { useToken } = theme

const messages = {
  en: enLanguage,
  es: esLanguage,
}

const AntdProvider = ({ children, locale, currentProperty }) => {
  const { formatMessage } = useIntl()
  const { token } = useToken()

  const validateMessages = {
    required: formatMessage({ id: 'requiredField' }, { fieldName: '${label}' }),
    types: {
      email: formatMessage({ id: 'formatEmail' }, { fieldName: '${label}' }),
    },
  }

  return (
    <ConfigProvider
      locale={supportedLocales[locale]}
      form={{ validateMessages }}
      theme={{
        token: {
          colorPrimary: currentProperty?.color ?? '#5accc8',
          colorBgLayout: '#fff',
          fontFamily: `open-sans, ${token.fontFamily}`,
          borderRadius: 7,
          colorBorder: '#d9d9d9',
          colorBorderSecondary: '#d9d9d9',
          colorLink: currentProperty?.color ?? '#5accc8',
        },
      }}
    >
      {children}
    </ConfigProvider>
  )
}

const IntlWrapper = ({ currentProperty, locale, children }) => {
  useEffect(() => {
    if (locale) {
      dayjs.locale(locale)
    }
  }, [locale])

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <AntdProvider
        locale={locale}
        children={children}
        currentProperty={currentProperty}
      />
    </IntlProvider>
  )
}

const mapStateToProps = (state) => ({
  locale: selectLocale(state),
  currentProperty: selectCurrentProperty(state),
})

export default connect(mapStateToProps, {})(IntlWrapper)
