import produce from 'immer'
import { DEFAULT_CURRENT_PROPERTY_CONST } from '../../util/constants/propertyConstants'
import { SET_CURRENT_PROPERTY, SET_LOCALE } from './constants'

const initialState = {
  locale: 'en',
  currentProperty: DEFAULT_CURRENT_PROPERTY_CONST,
}

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_LOCALE:
        draft.locale = action.locale
        break
      case SET_CURRENT_PROPERTY:
        draft.currentProperty = action.currentProperty
        break
      default:
        break
    }
  })

export default reducer
