import loadable from '../util/functions/loadable'
import { HomeOutlined } from '@ant-design/icons'

const AppLayout = loadable(() => import('../layouts/AppLayout/AppLayout'))

const Home = loadable(() => import('../views/Home/Home'))
const Checkout = loadable(() => import('../views/Checkout/Checkout'))

export const homePath = '/home'
export const checkoutPath = '/my-checkout'
export const loginPath = '/login'
export const signUpPath = '/signup'
export const forgotPasswordPath = '/forgot-password'
export const updatePasswordPath = '/update-password/:token'

const homeRoute = {
  name: 'home',
  icon: <HomeOutlined />,
  path: homePath,
  layout: AppLayout,
  component: Home,
}

const checkoutRoute = {
  name: 'checkout',
  icon: <HomeOutlined />,
  path: checkoutPath,
  layout: AppLayout,
  component: Checkout,
}

export const navRoutes = [homeRoute, checkoutRoute]
