import produce from 'immer'
import {
  SET_CART_ITEMS,
  ADD_CART_ITEM,
  EDIT_CART_ITEM,
  DEL_CART_ITEM,
  SET_CART,
  RESET_CART,
  DEL_ALL_CART_ITEM,
} from './constants'

const initialState = {
  cart: {
    guests: 0,
    petsNumber: 0,
    dates: [],
    items: [],
  },
}

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_CART:
        draft.cart = {
          ...draft.cart,
          ...action.cart,
        }
        break
      case SET_CART_ITEMS:
        draft.cart.items = action.items
        break
      case ADD_CART_ITEM:
        draft.cart.items.push(action.item)
        break
      case EDIT_CART_ITEM:
        {
          const findIndex = draft.cart.items.findIndex(
            (item) => item._id === action._id
          )

          if (findIndex >= 0) {
            draft.cart.items.splice(findIndex, 1, action.item)
          }
        }
        break

      case DEL_CART_ITEM:
        {
          const findIndex = draft.cart.items.findIndex(
            (item) => item._id === action._id
          )

          if (findIndex >= 0) {
            draft.cart.items.splice(findIndex, 1)
          }
        }
        break
      case DEL_ALL_CART_ITEM:
        draft.cart.items = []
        break
      case RESET_CART:
        draft.cart = { ...initialState.cart }
        break
      default:
        break
    }
  })

export default reducer
